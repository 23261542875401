.header {
  background-color: #f5f5f5;
  height: 285px;
}

.header__content {
  width: 1120px;
  /* height: 350px; */
  margin: auto;
}

.background-img__container {
  width: 880px;
  margin: auto;
  position: relative;
}

.background-img {
  position: absolute;
  background-repeat: no-repeat;
  left: 120;
}
