.navigation {
  width: 1120px;
  margin: auto;
  padding-top: 2rem;
}

.navigation-bar {
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 100%;
}

.navigation-bar li {
  list-style: none;
}

.navigation-bar__blank {
  flex: 2;
}

.navigation-bar__nav-link {
  display: flex;
  gap: 1rem;
  border-radius: 8px;
  padding: 0.6rem 1.5rem;
  background-color: #fff;
  border: 1px solid #fc997c;
}

/* .navigation__container {
  background-color: #f5f5f5;
}

.navigation {
  width: 1120px;
  margin: auto;
}

.navigation__wrapper {
  display: flex;
  gap: 2rem;
}

.navigation__menu {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.navigation__bar {
  display: flex;
  justify-content: space-between;
}

.navigation__nav-links {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.navigation__nav-link {
  display: flex;
  gap: 0.5rem;
  border-radius: 8px;
  list-style: none;
  padding: 0.6rem 1.5rem;
  background-color: #fff;
}

.navigation__nav-link p {
  display: inline-block;
}

.navigation__profile {
  display: flex;
  gap: 2rem;
  align-items: center;
} */
